import { GetHealthEducationByCategoryIdInput, HealthEducation } from './../graphql/types/types';
import { HEALTHEDUCATION_BY_CATEGORY } from './../graphql/queries/queries';
import { Injectable } from '@angular/core';
import { Apollo } from 'apollo-angular';
import { HEALTHEDUCATION, HEALTHEDUCATIONS } from '../graphql/queries/queries';
import { GetHealthEducationInput, PaginationOptions } from '../graphql/types/types';

@Injectable({
	providedIn: 'root'
})
export class HealthEducationService {

	constructor(private apollo: Apollo) { }

	listHealthEducations(Obj: PaginationOptions) {
		return this.apollo.query<any>({
			query: HEALTHEDUCATIONS,
			variables: { paginationOptions: Obj }
		})
	}

	healthEducation(Obj: GetHealthEducationInput) {
		return this.apollo.query<any>({
			query: HEALTHEDUCATION,
			variables: { getHealthEducationInput: Obj }
		})
	}

	healthEducationByCategory(Obj:GetHealthEducationByCategoryIdInput){
		return this.apollo.query<[HealthEducation]>({
			query: HEALTHEDUCATION_BY_CATEGORY,
			variables: { getHealthEducationByCategoryIdInput: Obj }
		})
	}
}
