import { Injectable } from '@angular/core';
import { Apollo } from 'apollo-angular';
import { SLOGANS } from '../graphql/queries/queries';
import { PaginationOptions, Slogan } from '../graphql/types/types';

@Injectable({
	providedIn: 'root'
})
export class SloganService {

	constructor(private apollo: Apollo) { }

	listSlogan() {
		return this.apollo.query<[Slogan]>({
			query: SLOGANS
		})
	}
}


