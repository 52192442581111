import { Injectable } from '@angular/core';
import { Apollo } from 'apollo-angular';
import { ABOUT } from '../graphql/queries/queries';
import { About, PaginationOptions } from '../graphql/types/types';

@Injectable({
	providedIn: 'root'
})
export class AboutService {

	constructor(private apollo: Apollo) { }

	listAbout() {
		return this.apollo.query<About>({
			query: ABOUT
		})
	}
}
