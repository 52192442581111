import { New } from './../graphql/types/types';
import { Injectable } from '@angular/core';
import { Apollo } from 'apollo-angular';
import { ALLNEWS, NEWSDETAILS, NEWS_BY_CATEGORY } from '../graphql/queries/queries';
import { PaginationOptions, GetNewsInput } from '../graphql/types/types';

@Injectable({
  providedIn: 'root'
})
export class NewService {

  constructor (private apollo: Apollo) {}

  listAllNews(Obj:PaginationOptions){
    return this.apollo.query<[New]>({
      query:ALLNEWS,
      variables: { paginationOptions: Obj }
    })
  }

  listNew(Obj: GetNewsInput) {
    return this.apollo.query<New>({
      query: NEWSDETAILS,
      variables: { getNewsInput: Obj }
    })
  }

  listNewsByCatgory(Obj: PaginationOptions, newsCategoryId:string){
    return this.apollo.query<New>({
      query: NEWS_BY_CATEGORY,
      variables: { paginationOptions: Obj, newsCategoryId: newsCategoryId }
    })
  }
}
